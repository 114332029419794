import React from "react";
import Picture from "../../../images/home/Marmiton/Marmiton-Project/imgText.jpg";
import Picture1 from "../../../images/home/Marmiton/Marmiton-Project/img01.jpg";
import Picture2 from "../../../images/home/Marmiton/Marmiton-Project/img02.jpg";
import Picture3 from "../../../images/home/Marmiton/Marmiton-Project/img03.jpg";
import Picture4 from "../../../images/home/Marmiton/Marmiton-Project/img04.jpg";
import Picture5 from "../../../images/home/Marmiton/Marmiton-Project/img05.jpg";
import Picture6 from "../../../images/home/Marmiton/Marmiton-Project/img06.jpg";

const MarmitonPage = () => {
  return (
    <div className="cover-container">
      <div className={`productViewFirst`} style={{ "--bg-color": "rgb(229,186,167)",  "--text-color": "black" }} >
        <h1 className="productViewFirst_h1">De l'astuce à l'assiette • Marmiton x Michel Lafon</h1>
        <h2 className="productViewFirst_h2">
            Conception • Création graphique et éditoriale • Réalisation
        </h2>
        <h2 className={`productViewFirst_p`} style={{ "--text-font": "1.5vw"}} >
        Des techniques de base expliquées en pas-à-pas, des recettes gourmandes avec de nombreuses variantes d'associations, 
        de saveurs pour ne jamais être à court d'idées Des astuces, des trucs, des conseils pour tout réussir en cuisine. 
        Les bonnes recettes et les meilleures astuces de Juliette Delacroix et de la communauté Marmiton pour une cuisine gourmande et sans effort : 
        exit charge mentale, welcome happiness !
        Épater ses invités, réussir sa mayonnaise, sa crème anglaise et son caramel, éviter le gaspillage, 
        avoir une viande tendre et juteuse, manger selon les saisons et de manière plus responsable.
        <br />
        POUR TOUTES LES QUESTIONS CULINAIRES QUI SE POSENT AU QUOTIDIEN. CE LIVRE A LA SOLUTION !
        </h2>
      </div>
       {/* <div className="inside-page">
        <img className="coverimg" src={Picture} alt="Page" />
      </div> */}
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture4} alt="Page 4" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture5} alt="Page 5" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture6} alt="Page 6" />
      </div>
    </div>
  );
};

export default MarmitonPage;
