import React, { useState } from "react";
import "./Css/form.css";

function Form() {

  const initialFormData = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };
  const [formData, setFormData] = useState(initialFormData)

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://api.ediclic.com/sendemail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log("Email sent successfully!");
        console.log(response)
        setFormData(initialFormData);
      } else {
        console.error("Failed to send email.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Nom *"
          required
          onChange={handleChange}
        />
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Email *"
          required
          onChange={handleChange}
        />
        <input
          type="text"
          id="subject"
          name="subject"
          placeholder="Sujet *"
          onChange={handleChange}
        />
        <textarea
          id="message"
          name="message"
          placeholder="Message"
          rows="6"
          onChange={handleChange}
        ></textarea>
        <button type="submit">Envoyer</button>
      </form>
    </div>
  );
}

export default Form;
