import React from "react";
import Picture from "../../../images/home/ArtColoriages/Art-en-Coloriages-Project/Page_Text.jpg";
import Picture1 from "../../../images/home/ArtColoriages/Art-en-Coloriages-Project/Page_1.jpg";
import Picture2 from "../../../images/home/ArtColoriages/Art-en-Coloriages-Project/Page_2.jpg";
import Picture3 from "../../../images/home/ArtColoriages/Art-en-Coloriages-Project/Page_3.jpg";
import Picture4 from "../../../images/home/ArtColoriages/Art-en-Coloriages-Project/Page_4.jpg";
// import Picture5 from "../../../images/home/cover7/coverproject7/Page_5.jpg";

const ArtColoriagesPage = () => {
  return (
    <div className="cover-container">
      <div className={`productViewFirst`} style={{ "--bg-color": "rgb(226,224,248)", "--text-color": "black" }}>
        <h1 className="productViewFirst_h1">L'Art  en coloriage Chefs d'oeuvres <br />• Citadelles & Mazenod</h1>
        <h2 className="productViewFirst_h2">
          Conception • Création graphique et éditoriale
           • Réalisation
        </h2>
        <h2 className={`productViewFirst_p`} style={{ "--text-font": "1.5vw"}} >
        Cet ouvrage marque le début d'une collaboration avec Citadelles & Mazenod, 
        éditeur de livres d'art d'exception, qui souhaitait s'adresser à un public 
        familial et ont fait appel à Édiclic, pour concevoir une collection. 
        30 oeuvres majeures de l'histoire de l'art, du XVe au XXe siècle, 
        à découvrir et à colorier. Un texte captivant explique le travail 
        de chaque peintre, ses techniques, retrace le contexte historique 
        et les influences artistiques de son époque. Minutieusement reproduites au trait, 
        les oeuvres invitent au coloriage les amateurs de 7 à 77 ans ! Les pages, détachables, sont à offrir ou à encadrer.
        </h2>
      </div>
      {/* <div className="inside-page">
        <img className="coverimg" src={Picture} alt="Page" />
      </div> */}
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture4} alt="Page 4" />
      </div>
    </div>
  );
};

export default ArtColoriagesPage;
