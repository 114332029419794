import React from "react";
import Picture from "../../../images/home/ThermomixInternational/ThermomixInternational-Project/ThermomixInternational_Text.jpg";
import Picture1 from "../../../images/home/ThermomixInternational/ThermomixInternational-Project/ThermomixInternational_Page1.jpg";
import Picture2 from "../../../images/home/ThermomixInternational/ThermomixInternational-Project/ThermomixInternational_Page2.jpg";
// import Picture3 from "../../../images/home/ThermomixInternational/ThermomixInternational-Project/ThermomixInternational_Page3.jpg";
import Picture4 from "../../../images/home/ThermomixInternational/ThermomixInternational-Project/ThermomixInternational_Page4.jpg";
import Picture5 from "../../../images/home/ThermomixInternational/ThermomixInternational-Project/ThermomixInternational_Page5.jpg";
import Picture6 from "../../../images/home/ThermomixInternational/ThermomixInternational-Project/ThermomixInternational_Page6.jpg";
import Picture7 from "../../../images/home/ThermomixInternational/ThermomixInternational-Project/ThermomixInternational_Page7.jpg";
import Picture8 from "../../../images/home/ThermomixInternational/ThermomixInternational-Project/ThermomixInternational_Page8.jpg";
import Picture9 from "../../../images/home/ThermomixInternational/ThermomixInternational-Project/ThermomixInternational_Page9.jpg";

const ThermomixInternationalPage = () => {
  return (
    <div className="cover-container">
      <div className={`productViewFirst`} style={{ "--bg-color": "rgb(161,197,193)",  "--text-color": "rgb(255,255,255)" }} >
        <h1 className="productViewFirst_h1">Production livres • Vorwerk International </h1>
        <h2 className="productViewFirst_h2">
          Conception • Création graphique et éditoriale • Réalisation • Impression
        </h2>
        <h2 className={`productViewFirst_p`} style={{ "--text-font": "1.6vw"}} >
        Pour le groupe Vorwerk, Édiclic a concu et réalisé plusieurs livres de cuisine en anglais, 
        puis a organisé la traduction dans les langues des pays du groupe : espagnol, italien, roumain, 
        français, portugais, turque et même mandarin ! Des livres concus en coédition pour une production 
        ajustées et des coûts d'impression optimsés : les images sont communes à toutes les versions,
        seule la plaque noir (les textes) changent d'une édition à l'autre. La couverture elle reste "libre", 
        pour le titre Ecofriendly par exemple deux modèles ont été suivis. 
        </h2>
      </div>
     {/* <div className="inside-page">
        <img className="coverimg" src={Picture} alt="Page" />
      </div> */}
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      {/* <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div> */}
      <div className="inside-page">
        <img className="coverimg" src={Picture4} alt="Page 4" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture5} alt="Page 5" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture6} alt="Page 6" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture7} alt="Page 7" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture8} alt="Page 8" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture9} alt="Page 9" />
      </div>
    </div>
  );
};

export default ThermomixInternationalPage;
