import React from "react";
import Picture from "../../../images/home/Simplissime/Simplissime_Project/Page_Text.jpg";
import Picture1 from "../../../images/home/Simplissime/Simplissime_Project/Page_1.jpg";
import Picture2 from "../../../images/home/Simplissime/Simplissime_Project/Page_2.jpg";
import Picture3 from "../../../images/home/Simplissime/Simplissime_Project/Page_3.jpg";
import Picture4 from "../../../images/home/Simplissime/Simplissime_Project/Page_4.jpg";
import Picture5 from "../../../images/home/Simplissime/Simplissime_Project/Page_5.jpg";
import Picture6 from "../../../images/home/Simplissime/Simplissime_Project/Page_6.jpg";
import Picture7 from "../../../images/home/Simplissime/Simplissime_Project/Page_7.jpg";
import "../../Css/productViewFirst.css";

const SimplissimePage = () => {
  // const backgroundColor = getBackgroundColor();

  return (
    <div className="cover-container">
      <div className={`productViewFirst`} style={{ "--bg-color": "rgb(199,216,226)", "--text-color": "black" }}>
        <h1 className="productViewFirst_h1">Simplissime • Hachette Pratique</h1>
        <h2 className="productViewFirst_h2">
          Conception • Création graphique et éditoriale
           • Réalisation
        </h2>
        <h2 className={`productViewFirst_p`} style={{ "--text-font": "1.7vw"}} >
          Édiclic a accompagné l'auteur Jean-François Mallet dans la création de
          la collection Simplissime. Le projet depuis quelques années dans un
          tiroir dont le nom de code était La cuisine en une ligne est devenue
          un livre (puis une collection) culte de cuisine pour tous où
          l'imédiateté visuelle de la maquette, la présentation des ingrédients
          et des textes courts et accessibles (donnant souvent des associations
          de saveurs originales) ont dédramatisé la cuisine.
        </h2>
      </div>
      {/* <div className="inside-page">
        <img className="coverimg" src={Picture} alt="Page" />
      </div> */}
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture4} alt="Page 4" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture5} alt="Page 5" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture6} alt="Page 6" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture7} alt="Page 7" />
      </div>
    </div>
  );
};


export default SimplissimePage;
