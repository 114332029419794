import React from "react";
// import Picture from "../../../images/home/PetitCahier/PetitCahier-Project/Miko_Text.jpg";
import Picture1 from "../../../images/home/PetitCahier/PetitCahier-Project/PetitCahier_Page1.jpg";
import Picture2 from "../../../images/home/PetitCahier/PetitCahier-Project/PetitCahier_Page2.jpg";
import Picture3 from "../../../images/home/PetitCahier/PetitCahier-Project/PetitCahier_Page3.jpg";
// import Picture4 from "../../../images/home/PetitCahier/PetitCahier-Project/PetitCahier_Page4.jpg";

const PetitCahierPage = () => {
  return (
    <div className="cover-container">
     {/* <div className="inside-page">
        <img className="coverimg" src={Picture} alt="Page" />
      </div> */}
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div>
      {/* <div className="inside-page">
        <img className="coverimg" src={Picture4} alt="Page 4" />
      </div> */}
      {/* <div className="inside-page">
        <img className="cover8img4" src={Picture4} alt="Page 4" />
      </div>
      <div className="inside-page">
        <img className="cover8img5" src={Picture5} alt="Page 5" />
      </div> */}
    </div>
  );
};

export default PetitCahierPage;
