import React from "react";
import Picture1 from "../../../images/home/CUCINA POVERA/01.jpg";
import Picture2 from "../../../images/home/CUCINA POVERA/02.jpg";
import Picture3 from "../../../images/home/CUCINA POVERA/03.jpg";
import Picture4 from "../../../images/home/CUCINA POVERA/04.jpg";
import Picture5 from "../../../images/home/CUCINA POVERA/05.jpg";

const CucinaPage = () => {
  return (
    <div className="cover-container">
     <div className={`productViewFirst`} style={{ "--bg-color": "rgb(254,217,87)", "--text-color": "black" }}>
        <h1 className="productViewFirst_h1">Cucina Povera - Hachette Pratique</h1>
        <h2 className={`productViewFirst_p`} style={{ "--text-font": "1.7vw"}} >
          <h2 className="productViewFirst_h2">
            Conception • Création graphique et éditoriale • Réalisation
          </h2> 
          Ce livre met à l'honneur la  cucina povera, une cuisine paysanne italienne qui valorise les produits locaux et de saison grâce à des recettes simples, économiques et créatives, souvent conçues par nécessité, pour faire beaucoup avec peu de choses. Elle se caractérise par l'utilisation ingénieuse des ingrédients disponibles, ainsi que par un respect pour la bonne nourriture et la santé.
          Laura Zavan propose dans ce livre sa vision de la  cucina povera, avec ses ingrédients fétiches, des portraits de légumes typiquement italiens et peu connus en France comme le radicchio di Treviso tardif, puntarelle ou cicoria, friggitelli, cime di rapa, etc, ainsi que des produits typiques (stracchino, caciocavallo, taleggio...)
          Retrouvez plus de 80 recettes simples ou un peu plus élaborées, naturelles et savoureuses : Farinata alle cipolle (galette de pois chiches aux oignons), Focaccia barese, Frittata di spaghetti, Torta di Borgotaro alle erbe e parmigiano (tourte aux herbes), pasta & fagioli (pâtes & haricots), Pasta & ceci (pâtes & pois chiches), Pâtes au chou-fleur et fruits secs à la sicilienne.        </h2>
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture4} alt="Page 4" />
      </div> 
       <div className="inside-page">
        <img className="coverimg" src={Picture5} alt="Page 4" />
      </div>
    </div>
  );
};

export default CucinaPage;
