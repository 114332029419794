import React from "react";
import Picture from "../../../images/home/ThermomixPoisson/Thermomix_Poisson_Project/Page-Text.jpg";
import Picture1 from "../../../images/home/ThermomixPoisson/Thermomix_Poisson_Project/Page-1.jpg";
import Picture2 from "../../../images/home/ThermomixPoisson/Thermomix_Poisson_Project/Page-2.jpg";
import Picture3 from "../../../images/home/ThermomixPoisson/Thermomix_Poisson_Project/Page-3.jpg";
import Picture4 from "../../../images/home/ThermomixPoisson/Thermomix_Poisson_Project/Page-4.jpg";

const ThermomixPoissonPage = () => {
  return (
    <div className="cover-container">
      <div className={`productViewFirst`} style={{ "--bg-color": "rgb(134,208,243)",  "--text-color": "rgb(255,255,255)" }} >
        <h1 className="productViewFirst_h1">Poissons et fruits de mer • Thermomix - Vorwerk</h1>
        <h2 className="productViewFirst_h2">
        Conception • Création graphique et éditoriale • Réalisation
        </h2>
        <h2 className={`productViewFirst_p`} style={{ "--text-font": "1.4vw"}} >
        Le Livre de recettes Poissons et fruits de mer est le guide idéal pour tout savoir sur la préparation de bons plats de poissons!
        <br />
        Plus de 110 recettes marines fraîches et gourmandes, déclinées en sauces, mises en bouche, entrées et plats,
        à réaliser facilement avec votre Thermomix®, votre allié culinaire. Qu’il soit servi en apéritif, 
        en entrée ou en plat de résistance ce livre vous aidera à choisir, couper, cuisiner, préparer et accompagner votre poisson.
        Au fil des pages, les conseils nutrition d’Ysabelle Levasseur, diététicienne-nutritionniste, 
        et des informations sur les produits de la mer, leur saisonnalité, leurs apports nutritionnels ainsi que des accords mets-vins 
        et conseils œnologiques qui vous guideront pour bien les cuisiner.
        </h2>
      </div>
       {/* <div className="inside-page">
        <img className="coverimg" src={Picture} alt="Page" />
      </div> */}
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture4} alt="Page 4" />
      </div>
    </div>
  );
};

export default ThermomixPoissonPage;
