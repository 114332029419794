import React from "react";
import Picture1 from "../../../images/home/MAISON DEGLON/01.jpg";
import Picture2 from "../../../images/home/MAISON DEGLON/02.jpg";
import Picture3 from "../../../images/home/MAISON DEGLON/03.jpg";
import Picture4 from "../../../images/home/MAISON DEGLON/04.jpg";
import Picture5 from "../../../images/home/MAISON DEGLON/05.jpg";

const MaisonPage = () => {
  return (
    <div className="cover-container">
     <div className={`productViewFirst`} style={{ "--bg-color": "#D9D9D9", "--text-color": "black" }}>
        <h1 className="productViewFirst_h1">Maison Déglon - Coutellerie Déglon</h1>
        <h1 className="productViewFirst_h1">Du couteau à l'assiette, une transmission familiale</h1>
        <h2 className={`productViewFirst_p`} style={{ "--text-font": "1.7vw"}} >
          <h2 className="productViewFirst_h2">
          Conception • Création graphique et éditoriale • Impression
          </h2> 
          Au cœur de l’Auvergne, s’écrit une histoire familiale que porte Nadia Déglon. Épouse de Thierry Déglon, coutelier renommé, et mère de quatre enfants, Nadia insuffle à sa famille et à ses proches une passion pour les plaisirs de la table et la convivialité.
Dans cet ouvrage, Nadia nous partage une cuisine faite d’ingrédients simples, qui se prépare à l’avance, réconfortante mais toujours délicate ; 50 recettes au fil des saisons, sublimées par les magnifiques photos de Valérie Lhomme.
          Et comme la cuisine est avant tout une histoire de transmission familiale, deux de ses enfants, devenus professionnels de la cuisine, s’associent et livrent également leurs meilleures recettes. 

          Outre sa créativité et son talent, Nadia dispose également d’une magnifique collection d’outils, conçus et fabriqués par la Maison Déglon.
          Fondée en 1921, cette entreprise familiale depuis quatre générations, située à Thiers, berceau français de la coutellerie, perpétue un savoir-faire exceptionnel reconnu par les chefs du monde entier.
        </h2>
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture4} alt="Page 4" />
      </div> 
       <div className="inside-page">
        <img className="coverimg" src={Picture5} alt="Page 4" />
      </div>
    </div>
  );
};

export default MaisonPage;
