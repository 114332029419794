import React from "react";
import Picture from "../../../images/home/MonPetit/MonPetit-Project/MonPetit_PageText.jpg";
import Picture1 from "../../../images/home/MonPetit/MonPetit-Project/MonPetit_Page1.jpg";
import Picture2 from "../../../images/home/MonPetit/MonPetit-Project/MonPetit_Page2.jpg";
import Picture3 from "../../../images/home/MonPetit/MonPetit-Project/MonPetit_Page3.jpg";
// import Picture4 from "../../../images/home/MonPetit/MonPetit-Project/MonPetit_Page4.jpg";

const MonPetitPage = () => {
  return (
    <div className="cover-container">
      <div className={`productViewFirst`} style={{ "--bg-color": "rgb(178,181,188)",  "--text-color": "black" }} >
        <h1 className="productViewFirst_h1">Mon petite monde à décorer - Gallimard Jeunesse</h1>
        <h2 className="productViewFirst_h2">
            Conception • Création graphique et éditoriale • Réalisation
        </h2>
        <h2 className={`productViewFirst_p`} style={{ "--text-font": "1.6vw"}} >
        Une jolie collection de livres d'activités autour des contes classiques.
        De grandes doubles pages à colorier et à décorer avec plus de 150 autocollants repositionnables, 
        puis à lire à deux voix grâce aux mots illustrés placés au fil du texte.
        <br />
        <span className="additional-space">
        6 titres: Boucle d'Or - Le Petit Chaperon rouge - Les trois petits cochons - Hansel & Gretel - La petite poule rousse Le petit Poucet
        </span>
        </h2>
      </div>
     {/* <div className="inside-page">
        <img className="coverimg" src={Picture} alt="Page" />
      </div> */}
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div>
      {/* <div className="inside-page">
        <img className="coverimg" src={Picture4} alt="Page 4" />
      </div> */}
    </div>
  );
};

export default MonPetitPage;
