import React from "react";
import Picture from "../../../images/home/Les Petites/LES-PETITES-Project/Les-petites-routes-Text.jpg";
import Picture1 from "../../../images/home/Les Petites/LES-PETITES-Project/Les-petites-routes-Page1.jpg";
import Picture2 from "../../../images/home/Les Petites/LES-PETITES-Project/Les-petites-routes-Page2.jpg";
import Picture3 from "../../../images/home/Les Petites/LES-PETITES-Project/Les-petites-routes-Page3.jpg";
import Picture4 from "../../../images/home/Les Petites/LES-PETITES-Project/Les-petites-routes-Page4.jpg";
import Picture5 from "../../../images/home/Les Petites/LES-PETITES-Project/Les-petites-routes-Page5.jpg";

const LesPetitesPage = () => {
  return (
    <div className="cover-container">
      <div className={`productViewFirst`} style={{ "--bg-color": "rgb(234,240,238)",  "--text-color": "black" }} >
        <h1 className="productViewFirst_h1">Les Petites Routes • Éditions Gallimard</h1>
        <h2 className="productViewFirst_h2">
              Suivi éditorial 
        </h2>
        <h2 className={`productViewFirst_p`} style={{ "--text-font": "1.6vw"}} >
        Un road trip buissonnier au petit bonheur la France.Bifurquer au prochain croisement, 
        se laisser distraire, dérouter, tentés par un panneau, une adresse, une balade, 
        ou un coup de coeur. Respirer l'air du pays, sans autre but que de faire le plein d'énergie et de bonnes ondes. 
        Rencontrer celles et ceux qui se réinventent loin des grands axes. <br />Éviter les péages et les circuits balisés. 
        C'est avec ces règles de conduite que Laurence et Fabrice ont bouclé un tour de France de l'intérieur, 
        un voyage à la maison en quelque sorte, propice au dépaysement de proximité.
        </h2>
      </div>
       {/* <div className="inside-page">
        <img className="coverimg" src={Picture} alt="Page" />
      </div> */}
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture4} alt="Page 4" />
      </div>
      {/* <div className="inside-page">
        <img className="coverimg" src={Picture5} alt="Page 5" />
      </div> */}
    </div>
  );
};

export default LesPetitesPage;
