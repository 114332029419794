import React from "react";
import Picture from "../../../images/home/EnqueteDieu/EnqueteDieu-Project/EnqueteDieu_Text.jpg";
import Picture1 from "../../../images/home/EnqueteDieu/EnqueteDieu-Project/EnqueteDieu_Page1.jpg";
import Picture2 from "../../../images/home/EnqueteDieu/EnqueteDieu-Project/EnqueteDieu_Page2.jpg";
// import Picture3 from "../../../images/home/EnqueteDieu/EnqueteDieu-Project/EnqueteDieu_Page3.jpg";
import Picture4 from "../../../images/home/EnqueteDieu/EnqueteDieu-Project/EnqueteDieu_Page4.jpg";
// import Picture5 from "../../../images/home/EnqueteDieu/EnqueteDieu-Project/EnqueteDieu_Page5.jpg";

const EnquetePage = () => {
  return (
    
    <div className="cover-container">
      <div className={`productViewFirst`} style={{ "--bg-color": "rgb(254,217,87)",  "--text-color": "black" }} >
        <h1 className="productViewFirst_h1">Enquête sur Dieu • Éditions Mame</h1>
        <h2 className="productViewFirst_h2">
        Conception • Création graphique et éditoriale • Réalisation
        </h2>
        <h2 className={`productViewFirst_p`} style={{ "--text-font": "1.6vw"}} >
        Un livre ludique pour découvrir la foi des chrétiens, à partir des interrogations métaphysiques et drôles de Thomas et Sophie, 
        deux petits héros de bande dessinée. Des réponses claires et des renvois de page en page, sous forme d'enquête. 
        Un livre qui se lit au gré des envies et des questionnements des enfants.
        <br />
        Ce livre a reçu le prix de la librairie religieuse 2018.
        <br /> Il a été décliné en collections : les enquêtes de Thomas et Sophie 
        avec des titres sur les religions, une Bible et un missel.
        </h2>
      </div>
     {/* <div className="inside-page">
        <img className="coverimg" src={Picture} alt="Page" />
      </div> */}
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      {/* <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div> */}
      <div className="inside-page">
        <img className="coverimg" src={Picture4} alt="Page 4" />
      </div>
      {/* <div className="inside-page">
        <img className="coverimg" src={Picture5} alt="Page 5" />
      </div> */}
    </div>
  );
};

export default EnquetePage;
