import React from "react";
import Picture from "../../../images/home/ArtColoriagesXX/ArtColoriages-Project/PageText.jpg";
import Picture1 from "../../../images/home/ArtColoriagesXX/ArtColoriages-Project/Page1.jpg";
import Picture2 from "../../../images/home/ArtColoriagesXX/ArtColoriages-Project/Page2.jpg";
import Picture3 from "../../../images/home/ArtColoriagesXX/ArtColoriages-Project/Page3.jpg";
import Picture4 from "../../../images/home/ArtColoriagesXX/ArtColoriages-Project/Page4.jpg";

const ColoriagesPage = () => {
  return (
    <div className="cover-container">
      <div className={`productViewFirst`} style={{ "--bg-color": "rgb(237,206,204)", "--text-color": "black" }}>
        <h1 className="productViewFirst_h1">L'Art en coloriages XXe siècle <br />• Citadelles & Mazenod</h1>
        <h2 className="productViewFirst_h2">
          Conception • Création graphique et éditoriale
           • Réalisation
        </h2>
        <h2 className={`productViewFirst_p`} style={{ "--text-font": "1.7vw"}} >
        De Gustav Klimt à Takashi Murakami, ce livre rassemble 
        30 peintures phares à découvrir et à colorier.
         Un texte vivant présente les artistes, leurs techniques et leurs sujets 
         d'inspiration pour comprendre les grandes évolutions de l'art du XXe siècle. 
         Minutieusement reproduites au trait, les oeuvres invitent au coloriage 
         les amateurs de 7 à 77 ans ! Les pages, détachables, sont à offrir ou à encadrer.
        </h2>
      </div>
      {/* <div class="et_pb_row et_pb_row_0">
        <div class="et_pb_column">
          <div class="et_pb_text et_pb_text_0">
            <h1>
              De Gustav Klimt à Takashi Murakami, ce livre rassemble 30
              peintures phares à découvrir et à colorier. Un texte vivant
              présente les artistes, leurs techniques et leurs sujets
              d'inspiration pour comprendre les grandes évolutions de l'art du
              XXe siècle. Minutieusement reproduites au trait, les oeuvres
              invitent au coloriage les amateurs de 7 à 77 ans ! Les pages,
              détachables, sont à offrir ou à encadrer.
            </h1>
            <div class="et_pb_text et_pb_text_1">
              <p>Conception • Création graphique et éditoriale • Réalisation</p>
            </div>
          </div>
        </div>
      </div> */}
       {/* <div className="inside-page">
        <img className="coverimg" src={Picture} alt="Page" />
      </div> */}
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture4} alt="Page 4" />
      </div>
    </div>
  );
};

export default ColoriagesPage;
