import React from "react";
import Picture from "../../../images/home/Prenoms/Prenoms-Project/Prenoms_Text.jpg";
import Picture1 from "../../../images/home/Prenoms/Prenoms-Project/Prenoms_Page1.jpg";
import Picture2 from "../../../images/home/Prenoms/Prenoms-Project/Prenoms_Page2.jpg";
import Picture3 from "../../../images/home/Prenoms/Prenoms-Project/Prenoms_Page3.jpg";
import Picture4 from "../../../images/home/Prenoms/Prenoms-Project/Prenoms_Page4.jpg";
import HomeFooter from "../../footer/HomeFooter";

const PrenomsPage = () => {
  return (
    <div className="cover-container">
      <div className={`productViewFirst`} style={{ "--bg-color": "rgb(209,227,203)",  "--text-color": "black" }} >
        <h1 className="productViewFirst_h1">Guide des Prénoms • depuis 2016 <br />• Éditions Solar</h1>
        <h2 className="productViewFirst_h2">
            Conception • Création graphique et éditoriale • Réalisation
        </h2>
        <h2 className={`productViewFirst_p`} style={{ "--text-font": "1.4vw"}} >
        Cautionnée par le magazine Parents, la référence des guides des prénoms ! Plus de 10 000 prénoms, 
        des tops thématiques et de l'espace pour s'approprier le livre ! À redécouvrir avec l'enfant plus tard.
        Trouver un prénom pour son bébé à naître est l'une des grandes décisions des futurs parents ! 
        Tout a été étudié dans cet ouvrage - le plus complet du marché - pour que la recherche du prénom de bébé soit aisée, riche, avertie et sereine.
        <br />
        <span className="additional-space">
        On y trouve :
        un "tuto' expliquant comment choisir le prénom, les règles, les implications mais aussi les prénoms en vogue et le carnet rose des people.
        une partie à personnaliser avec test, réponses au test, arbre généalogique, les short-lists du papa et de la maman 
        (et un système de picto à colorier au fil du livre pour marquer et repérer facilement les prénoms qu'on aime au fil de la lecture).
        un corpus séparé fille / garçon, présentant plus de 3 000 entrées de prénoms, plus de 7 000 variantes, 30 tops thématiques : les prénoms médiévaux, 
        les prénoms du cinéma, les prénoms rares, les prénoms régionaux, les prénoms bobos, les prénoms bibliques, les prénoms de la mythologie, les prénoms de contes de fées.
        Pour chaque entrée de prénom on trouve : 
        étymologie / date de fête / traits de caractère / couleur / nombre / variantes / personnages célèbres associés (dont le saint patron ou le héros de la mythologie) et les statistiques associées (en hausse - en baisse / fréquent - rare / âge moyen de personnes portant ce prénom)
        </span>
        </h2>
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture4} alt="Page 4" />
      </div>
    </div>
  );
};

export default PrenomsPage;
