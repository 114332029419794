import React from "react";
import Picture from "../../../images/home/Cerelia/Cerelia-Project/CEREALIA_Text.jpg";
import Picture1 from "../../../images/home/Cerelia/Cerelia-Project/CEREALIA_Page1.jpg";
import Picture2 from "../../../images/home/Cerelia/Cerelia-Project/CEREALIA_Page2.jpg";
import Picture3 from "../../../images/home/Cerelia/Cerelia-Project/CEREALIA_Page3.jpg";
import Picture4 from "../../../images/home/Cerelia/Cerelia-Project/CEREALIA_Page4.jpg";
import Picture5 from "../../../images/home/Cerelia/Cerelia-Project/CEREALIA_Page5.jpg";

const CereliaPage = () => {
  return (
    <div className="cover-container">
      <div className={`productViewFirst`} style={{ "--bg-color": "rgb(176,63,29)",  "--text-color": "rgb(255,255,255)" }} >
        <h1 className="productViewFirst_h1">We are Cérélia • Livre d'entreprise CERELIA</h1>
        <h2 className="productViewFirst_h2">
        Conception • Création graphique et éditoriale • Réalisation 
        <br />
        • Impression
        </h2>
        <h2 className={`productViewFirst_p`} style={{ "--text-font": "1.7vw"}} >
        Cérélia, une entreprise agro-alimentaire en pleine croissance 
        (voire hypercroissance), ayant racheté plusieurs marques dans les 
        dernières années, a voulu prendre le temps de poser les valeurs partagées 
        par toutes les marques et favoriser ainsi le sentiment d'appartenance de ses salariés. 
        Ainsi est née l'idée du livre We are Cérélia. 
        </h2>
      </div>
        {/* <div className="inside-page">
        <img className="coverimg" src={Picture} alt="Page" />
      </div> */}
      {/* <div class="et_pb_row et_pb_row_0">
        <div class="et_pb_column">
          <div class="et_pb_text et_pb_text_0">
            <h1>
              Cérélia, une entreprise agro-alimentaire en pleine croissance
              (voire hypercroissance), ayant racheté plusieurs marques dans les
              dernières années, a voulu prendre le temps de poser les valeurs
              partagées par toutes les marques et favoriser ainsi le sentiment
              d'appartenance de ses salariés. Ainsi est née l'idée du livre We
              are Cérélia.
            </h1>
            <div class="et_pb_text et_pb_text_1">
              <p>
                Conception • Création graphique et éditoriale • Réalisation •
                Impression
              </p>
            </div>
          </div>
        </div>
      </div> */}
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture4} alt="Page 4" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture5} alt="Page 5" />
      </div>
    </div>
  );
};

export default CereliaPage;
