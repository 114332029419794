import React from "react";
import Picture from "../../../images/home/EnTrain/EnTrain-Project/EnTrain_Text.jpg";
import Picture1 from "../../../images/home/EnTrain/EnTrain-Project/EnTrain_Page1.jpg";
import Picture2 from "../../../images/home/EnTrain/EnTrain-Project/EnTrain_Page2.jpg";
import Picture3 from "../../../images/home/EnTrain/EnTrain-Project/EnTrain_Page3.jpg";
import Picture4 from "../../../images/home/EnTrain/EnTrain-Project/EnTrain_Page4.jpg";
import Picture5 from "../../../images/home/EnTrain/EnTrain-Project/EnTrain_Page5.jpg";

const EnTrainPage = () => {
  return (
    <div className="cover-container">
      <div className={`productViewFirst`} style={{ "--bg-color": "rgb(229,205,135)",  "--text-color": "black" }} >
        <h1 className="productViewFirst_h1">En train • Éditions Gallimard</h1>
        <h2 className="productViewFirst_h2">
            Recherche d'auteurs • Suivi éditorial 
        </h2>
        <h2 className={`productViewFirst_p`} style={{ "--text-font": "1.6vw"}} >
        Le trajet, c'est déjà le voyage. 
        Le voyage en train, c'est le voyage alternatif du XXIe s. 
        Pas seulement plus écologique : temps long, rythme lent, inscription 
        dans la réalité des paysages, des géographies humaines, c'est une autre 
        façon de voyager qui privilégie les rencontres et la découverte des cultures locales. 
        Un guide pratique et un beau livre qui propose de vivre 30 aventures en train, 
        de quelques jours à trois semaines à la découverte de l'Europe.
        <br />
        <span className="additional-space">
            Des itinéraires détaillés qui traversent des destinations culturelles majeures, 
            des lieux méconnus, des bifurcations dans l'arrière-pays. Autant d'expériences qui apportent à chaque voyage un parfum d'aventure.
        </span>
        </h2>
      </div>
       {/* <div className="inside-page">
        <img className="coverimg" src={Picture} alt="Page" />
      </div> */}
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture4} alt="Page 4" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture5} alt="Page 5" />
      </div>
    </div>
  );
};

export default EnTrainPage;
