import React from "react";
// import Picture from "../../../images/home/Prehistoire/Prehistoire-Project/Prehistoire_Text.jpg";
import Picture1 from "../../../images/home/Prehistoire/Prehistoire-Project/Prehistoire_Page1.jpg";
import Picture2 from "../../../images/home/Prehistoire/Prehistoire-Project/Prehistoire_Page2.jpg";
import Picture3 from "../../../images/home/Prehistoire/Prehistoire-Project/Prehistoire_Page3.jpg";
import Picture4 from "../../../images/home/Prehistoire/Prehistoire-Project/Prehistoire_Page4.jpg";
import Picture5 from "../../../images/home/Prehistoire/Prehistoire-Project/Prehistoire_Page5.jpg";
import Picture6 from "../../../images/home/Prehistoire/Prehistoire-Project/Prehistoire_Page6.jpg";

const PrehistoirePage = () => {
  return (
    <div className="cover-container">
      <div className={`productViewFirst`} style={{ "--bg-color": "rgb(252,251,249)", "--text-color": "black" }}>
        <h1 className="productViewFirst_h1">Reg'ART • L'art de la Préhistoire <br /> • Citadelles & Mazenod</h1>
        <h2 className="productViewFirst_h2">
          Conception • Création graphique et éditoriale
           • Réalisation
        </h2>
        <h2 className={`productViewFirst_p`} style={{ "--text-font": "1.7vw"}} >
        Conception d'une collection d'histoire de l'art 
        pour Citadelles & Mazenod : Reg'ART à la fois 
        ludique et encyclopédique. Avec ses pages à 
        trous permettant de dévoiler peu à peu les œuvres 
        et poser des questions dont les réponses 
        se trouvent au verso, le livre fait découvrir 20 
        oeuvres majeures d'une période ici la Préhistoire. 
        </h2>
      </div>
       {/* <div className="inside-page">
        <img className="coverimg" src={Picture} alt="Page" />
      </div> */}
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture4} alt="Page 4" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture5} alt="Page 5" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture6} alt="Page 6" />
      </div>
    </div>
  );
};

export default PrehistoirePage;
