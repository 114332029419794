import React from "react";
import Picture from "../../../images/home/Haussmann/Haussmann-Project/HAUSSMANN_Text.jpg";
import Picture1 from "../../../images/home/Haussmann/Haussmann-Project/HAUSSMANN_Page1.jpg";
import Picture2 from "../../../images/home/Haussmann/Haussmann-Project/HAUSSMANN_Page2.jpg";
import Picture3 from "../../../images/home/Haussmann/Haussmann-Project/HAUSSMANN_Page3.jpg";
import Picture4 from "../../../images/home/Haussmann/Haussmann-Project/HAUSSMANN_Page4.jpg";

const HaussmannPage = () => {
  return (
    <div className="cover-container">
      <div className={`productViewFirst`} style={{ "--bg-color": "rgb(183,221,234)",  "--text-color": "black" }} >
        <h1 className="productViewFirst_h1">29 Haussman • Société Générale</h1>
        <h2 className="productViewFirst_h2">
            Conception • Création graphique et éditoriale • Réalisation
        </h2>
        <h2 className={`productViewFirst_p`} style={{ "--text-font": "1.6vw"}} >
        29 Haussmann, une adresse tournée vers l'avenir.
        La direction de la Communication et la direction de l'Immobilier ont fait appel à Édiclic 
        pour mettre en lumière la rénovation du siège social chargé d'histoire de la Société Générale, en plein coeur de Paris.
        Un voyage historique, à la découverte de l’immeuble du 29 boulevard Haussmann, véritable chef-d’œuvre architectural et patrimonial, 
        transformé pour l’ancrer dans l’ère du « future of work » grâce à une importante rénovation entre janvier 2019 et juillet 2021. 
        <br />
        <a href=" https://www.societegenerale.com/fr/actualites/toutes-les-actualites/29-boulevard-haussmann-une-adresse-tournee-vers-lavenir" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "underline", color: "inherit" }}>
            www.societegenerale.com
          </a>
        </h2>
      </div>
      {/* <div className="inside-page">
        <img className="coverimg" src={Picture} alt="Page" />
      </div> */}
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture4} alt="Page 4" />
      </div>
    </div>
  );
};

export default HaussmannPage;
