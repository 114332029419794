import React from "react";
import Picture from "../../../images/home/FemmesDebordees/Femmes-Project/Femmes_Text.jpg";
import Picture1 from "../../../images/home/FemmesDebordees/Femmes-Project/Femmes_Page1.jpg";
import Picture2 from "../../../images/home/FemmesDebordees/Femmes-Project/Femmes_Page2.jpg";
import Picture3 from "../../../images/home/FemmesDebordees/Femmes-Project/Femmes_Page3.jpg";


const FemmesPage = () => {
  return (
    <div className="cover-container">
        <div className={`productViewFirst`} style={{ "--bg-color": "rgb(244,179,221)",  "--text-color": "black" }} >
        <h1 className="productViewFirst_h1">Femmes Débordées • Michel Lafon</h1>
        {/* <h2 className="productViewFirst_h2">
          Conception • Création graphique et éditoriale • Réalisation 
        </h2> */}
        <h2 className={`productViewFirst_p`} style={{ "--text-font": "1.6vw"}} >
        Un livre pour toutes celles qui sont débordées et qui ont décidé de ne plus l'être!
        <br />
        <span className="additional-space"></span>
        Ce guide vous permettra
        <br />
        • de devenir la reine de l'organisation
        <br />
        • d'avoir facilement une maison en ordre
        <br />
        • d'en finir avec l'éternel: Qu'est-ce que je vais faire à manger ce soir ?
        <br />
        • de gérer la PME familiale sans stress et dans la bonne humeur.
        <br />
        <span className="additional-space">
        Une multitude de conseils, de techniques et d'astuces d'organisation pour 
        se simplifier la vie, avec le ton drôle et déculpabilisant du site. Des to-do listes ainsi que des recettes faciles et express.
        </span>
        </h2>
      </div>
      {/* <div className="inside-page">
        <img className="coverimg" src={Picture} alt="Page" />
      </div> */}
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div>
    </div>
  );
};

export default FemmesPage;
