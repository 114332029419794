import React from "react";
import Picture from "../../../images/home/Intemporels/Intemporels-Project/Intemporels_Text.jpg";
import Picture1 from "../../../images/home/Intemporels/Intemporels-Project/Intemporels_Page1.jpg";
import Picture2 from "../../../images/home/Intemporels/Intemporels-Project/Intemporels_Page2.jpg";
import Picture3 from "../../../images/home/Intemporels/Intemporels-Project/Intemporels_Page3.jpg";
import Picture4 from "../../../images/home/Intemporels/Intemporels-Project/Intemporels_Page4.jpg";
import Picture5 from "../../../images/home/Intemporels/Intemporels-Project/Intemporels_Page5.jpg";

const IntemporelsPage = () => {
  return (
    <div className="cover-container">
    <div className={`productViewFirst`} style={{ "--bg-color": "rgb(245,215,91)",  "--text-color": "black" }} >
        <h1 className="productViewFirst_h1">Collection Les Intemporels  <br />• Éditions Flammarion</h1>
        <h2 className={`productViewFirst_p`} style={{ "--text-font": "1.7vw"}} >
        Une belle collection de livres de couture avec Astrid Le Provost, 
        créatrice des ouvrages et patrons Citronille,
        <br /> 
        <a href="http://www.citronille.fr" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "underline", color: "inherit" }}>
            www.citronille.fr
          </a>
        <br />
        Collaboration sur 4 titres : couture pour les bébés, les enfants, les demoiselles et même des modèles inspirée des livres Martine (Casterman) de notre enfance !
        30 modèles à coudre grâce aux patrons à taille réelle.
        </h2>
      </div>
       {/* <div className="inside-page">
        <img className="coverimg" src={Picture} alt="Page" />
      </div> */}
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture4} alt="Page 4" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture5} alt="Page 5" />
      </div>
    </div>
  );
};

export default IntemporelsPage;
