import React from "react";
import Picture from "../../../images/home/Thermomix/Thermomix-Project/Thermomix_Text.jpg";
import Picture1 from "../../../images/home/Thermomix/Thermomix-Project/Thermomix_Page1.jpg";
import Picture2 from "../../../images/home/Thermomix/Thermomix-Project/Thermomix_Page2.jpg";
import Picture3 from "../../../images/home/Thermomix/Thermomix-Project/Thermomix_Page3.jpg";
import Picture4 from "../../../images/home/Thermomix/Thermomix-Project/Thermomix_Page4.jpg";
import Picture5 from "../../../images/home/Thermomix/Thermomix-Project/Thermomix_Page5.jpg";

const ThermomixPage = () => {
  return (
    <div className="cover-container">
      <div className={`productViewFirst`} style={{ "--bg-color": "rgb(227,228,223)",  "--text-color": "black" }} >
        <h1 className="productViewFirst_h1">Recettes pour les Grands et les Tout-Petits <br />• Thermomix - Vorwerk</h1>
        <h2 className="productViewFirst_h2">
        Conception • Création graphique et éditoriale • Réalisation
        </h2>
        <h2 className={`productViewFirst_p`} style={{ "--text-font": "1.5vw"}} >
        Lorsque la famille s’agrandit, c’est toute l’organisation de la vie quotidienne et en particulier celle des repas qui est bouleversée. 
        <br />
        Nous avons conçu pour Thermomix/Vorwerk un livre de 65 recettes pour adultes et 130 déclinaisons pour les bébés (6-12 mois) 
        et jeunes enfants (1-3 ans). Des soupes, entrées, plats, avec ou sans protéines, desserts et même des idées de petit déjeuner à partager avec gourmandise en famille!
        <br />
        Avec les précieuses informations et conseils nutrition d’Ysabelle Levasseur, sur l’équilibre alimentaire, les étapes de la diversification, 
        les aliments santé ou les intolérances et allergies.
        </h2>
      </div>
       {/* <div className="inside-page">
        <img className="coverimg" src={Picture} alt="Page" />
      </div> */}
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture4} alt="Page 4" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture5} alt="Page 5" />
      </div>
    </div>
  );
};

export default ThermomixPage;
