import React from "react";
import Picture from "../../../images/home/Bethlehem/Bethlehem_Project/Page_Text.jpg";
import Picture1 from "../../../images/home/Bethlehem/Bethlehem_Project/Page_1.jpg";
import Picture2 from "../../../images/home/Bethlehem/Bethlehem_Project/Page_2.jpg";
import Picture3 from "../../../images/home/Bethlehem/Bethlehem_Project/Page_3.jpg";
import Picture4 from "../../../images/home/Bethlehem/Bethlehem_Project/Page_4.jpg";

const BethlehemPage = () => {
  return (
    <div className="cover-container">
      <div className={`productViewFirst`} style={{ "--bg-color": "rgb(217,177,165)",  "--text-color": "rgb(255,255,255)" }} >
        <h1 className="productViewFirst_h1">Livret Mobilités douces • Ville de Paris coopération Bethléem</h1>
        <h2 className="productViewFirst_h2">
        Conception • Création graphique et éditoriale • Réalisation • Impression
        </h2>
        <h2 className={`productViewFirst_p`} style={{ "--text-font": "1.7vw"}} >
        La Ville de Paris coopère avec de nombreuses villes dans 
        le monde pour donner son soutien et son expertise en termes 
        d'urbanisme. Pour sa coopération avec Bethléem, Ediclic a réalisé 
        un livret d'information et de jeux pour encourager les mobilités douces. 
        Conçu en aglais, il a ensuite été traduit en arabe pour être distribué dans les écoles de Bethléem. 
        </h2>
      </div>
       {/* <div className="inside-page">
        <img className="coverimg" src={Picture} alt="Page" />
      </div> */}
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture4} alt="Page 4" />
      </div>
    </div>
  );
};

export default BethlehemPage;
