import React from "react";
import Iconimage from "../../images/icon1-1.png";
import Iconimage2 from "../../images/icon2-1.png";
import Iconimage3 from "../../images/icon3-1.png";
import "../Css/Style.css";

const Icon = () => {
  return (
    <div className="icon-container">
      <div className="images">
        <div className="image-container">
          <img className="icon-conc" src={Iconimage} alt="Icon 1" />
          <p class="image-description">
            Créations de concepts éditoriaux autour d’auteurs, d’illustrateurs,
            de photographes, de marques.
          </p>
        </div>
        <div className="image-container">
          <img className="icon-crea" src={Iconimage2} alt="Icon 2" />
          <p class="image-description">
            Conception d’univers graphiques déclinés en mise en pages,
            illustrations, affiches, jeux, primes, etc.
          </p>
        </div>
        <div className="image-container">
          <img className="icon-con" src={Iconimage3} alt="Icon 3" />
          <p class="image-description">
            Étude de marché, stratégie de marques  éditoriales, recherche de la
            meilleure fabrication pour mettre en valeur le contenu.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Icon;
