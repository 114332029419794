import React from "react";
import Picture from "../../../images/home/CuisineTitesPoules/Cuisine-Poules-Project/tites_Text.jpg";
import Picture1 from "../../../images/home/CuisineTitesPoules/Cuisine-Poules-Project/tites_01.jpg";
import Picture2 from "../../../images/home/CuisineTitesPoules/Cuisine-Poules-Project/tites_02.jpg";
import Picture3 from "../../../images/home/CuisineTitesPoules/Cuisine-Poules-Project/tites_03.jpg";
// import Picture4 from "../../../images/home/cover12/coverproject12/tites_04.jpg";
// import Picture5 from "../../../images/home/cover12/coverproject12/tites_05.jpg";
// import Picture6 from "../../../images/home/cover12/coverproject12/tites_06.jpg";

const CuisineTitesPoulesPage = () => {
  return (
    <div className="cover-container">
      <div className={`productViewFirst`} style={{ "--bg-color": "rgb(239,232,224)",  "--text-color": "black" }} >
        <h1 className="productViewFirst_h1">La cuisine des P'tites Poules • Pocket Jeunesse</h1>
        <h2 className="productViewFirst_h2">
          Conception • Création graphique et éditoriale • Réalisation 
        </h2>
        <h2 className={`productViewFirst_p`} style={{ "--text-font": "1.6vw"}} >
        De délicieuses recettes sucrées et salées tirées des albums des 
        P'tites Poules! Dans ce livre de cuisine aux couleurs acidulées, 
        Carmen, Carmélito et leurs amis ont tout prévu pour vous régaler. 
        À vous les bons petits plats qui épatent le poulailler, du gratin de courgettes 
        au parmesan au jus de carottes et oranges en passant par les sablés croustillants. 
        Vous avez toujours rêvé de goûter à la dune de pop-corn de l'île de Toutégratos ou au fromage Kipu de Bélino ? 
        Alors, suivez le chef ! De multiples saveurs vous attendent.
        </h2>
      </div>
        {/* <div className="inside-page">
        <img className="coverimg" src={Picture} alt="Page 1" />
      </div> */}
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div>
      {/* <div className="inside-page">
        <img className="cover12img4" src={Picture4} alt="Page 4" />
      </div>
      <div className="inside-page">
        <img className="cover12img5" src={Picture5} alt="Page 5" />
      </div>
      <div className="inside-page">
        <img className="cover12img6" src={Picture6} alt="Page 6" />
      </div> */}
    </div>
  );
};

export default CuisineTitesPoulesPage;
