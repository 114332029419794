import React from "react";
import Picture from "../../../images/home/CuisineMonPere/CuisineMonPere-Project/Cuisine_Text.jpg";
import Picture1 from "../../../images/home/CuisineMonPere/CuisineMonPere-Project/Cuisine_Page1.jpg";
import Picture2 from "../../../images/home/CuisineMonPere/CuisineMonPere-Project/Cuisine_Page2.jpg";
import Picture3 from "../../../images/home/CuisineMonPere/CuisineMonPere-Project/Cuisine_Page3.jpg";
// import Picture4 from "../../../images/home/CuisineMonPere/CuisineMonPere-Project/Cuisine_Page4.jpg";
// import Picture5 from "../../../images/home/cover6/coverproject6/Cuisine_Page5.jpg";
// import Picture4 from "../../../images/home/cover6/coverproject6/content-page-4.jpg"

const CuisineMonPerePage = () => {
  return (
    <div className="cover-container">
      <div className={`productViewFirst`} style={{ "--bg-color": "rgb(181,175,175)",  "--text-color": "rgb(255,255,255)" }} >
        <h1 className="productViewFirst_h1">La cuisine de mon Père • Éditions Mango</h1>
        <h2 className="productViewFirst_h2">
          Conception • Création graphique et éditoriale • Réalisation 
        </h2>
        <h2 className={`productViewFirst_p`} style={{ "--text-font": "1.7vw"}} >
        L’histoire attachante d’une passion pour la cuisine et des souvenirs qu’elle évoque, 
        racontée par le chef Robert Abraham et sa fille Bérengère, aujourd’hui créatrice de recettes et styliste culinaire. 
        50 recettes préparées à quatre mains au fil des saisons.
        Placé dans la reliure du livre, un joli carnet vierge de 64 pages à remplir pour
        transmettre ses meilleures recettes à ceux que l’on aime.
        </h2>
      </div>
      {/* <div className="inside-page">
        <img className="coverimg" src={Picture} alt="Page" />
      </div> */}
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div>
      {/* <div className="inside-page">
        <img className="coverimg" src={Picture4} alt="Page 4" />
      </div> */}
      {/* <div className="inside-page">
        <img className="cover6img5" src={Picture5} alt="Page 5" />
      </div> */}
    </div>
  );
};

export default CuisineMonPerePage;
