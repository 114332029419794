import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomeFooter from "../footer/HomeFooter";
import Icon from "../main/Icon";
import ThermomixRecettesCover from "../../images/home/Thermomix/Thermomix-Cover.jpg";
import MadMaxCover from "../../images/home/MadMax/MadMax-Cover.jpg";
import FemmesCover from "../../images/home/FemmesDebordees/FemmesDebordees-Cover.jpg";
import LesPetitesCover from "../../images/home/Les Petites/LES-PETITES-ROUTES.jpg";
import MarmitonCover from "../../images/home/Marmiton/Marmiton-Cover.jpg";
import CuisineMonPereCover from "../../images/home/CuisineMonPere/Cuisine-Mon-Pere.jpg";
import ArtColoriagesCover from "../../images/home/ArtColoriages/Art-en-Coloriages-Cover.jpg";
import CereliaCover from "../../images/home/Cerelia/Cerelia-Cover.jpg";
import PrehistoireCover from "../../images/home/Prehistoire/Prehistoire-Cover.jpg";
import IntemporelsCover from "../../images/home/Intemporels/Intemporels-Cover.jpg";
import HaussmannCover from "../../images/home/Haussmann/Haussmann-Cover.jpg";
import CuisineTitesPoulesCover from "../../images/home/CuisineTitesPoules/Cuisine-Poules-Cover.jpg";
import MikoCover from "../../images/home/Miko/Miko-Cover.jpg";
import TrousselierCover from "../../images/home/Trousselier/Trousselier-Cover.jpg";
import PrenomsCover from "../../images/home/Prenoms/Prenoms-Cover.jpg";
import EnTrainCover from "../../images/home/EnTrain/EnTrain-Cover.jpg";
import SimplissimeCover from "../../images/home/Simplissime/Simplissime_Cover.jpg";
import ThermomixPoissonCover from "../../images/home/ThermomixPoisson/Thermomix_Poisson_Cover.jpg";
import BethlehemCover from "../../images/home/Bethlehem/Bethlehem_Cover.jpg";
import ArtColoriagesXXCover from "../../images/home/ArtColoriagesXX/ArtColoriages-Cover.jpg";
import MonPetitCover from "../../images/home/MonPetit/MonPetit-Cover.jpg";
import EnqueteDieuCover from "../../images/home/EnqueteDieu/EnqueteDieu-Cover.jpg";
import ThermomixInternationalCover from "../../images/home/ThermomixInternational/ThermomixInternational-Cover.jpg";
import PetitCahierCover from "../../images/home/PetitCahier/PetitCahier-Cover.jpg";
import RefugesCover from "../../images/home/Refuges/Refuges-Cover.jpg";
import CucianCover from "../../images/home/CUCINA POVERA/CUCINA POVERA-Cover.jpg";
import MaisonCover from "../../images/home/MAISON DEGLON/MAISON DEGLON-Cover.jpg";
import TheophileCover from "../../images/home/Theophile/Cover.jpg";
import "../Css/Style.css";

const Home = () => {
  const handleCover1Click = (e) => {
    window.location.href = "/projects/simplissime";
  };
  const handleCover2Click = (e) => {
    window.location.href = "/projects/préhistoire";
  };
  const handleCover3Click = (e) => {
    window.location.href = "/projects/art-en-coloriages";
  };

  const handleCover4Click = (e) => {
    window.location.href = "/projects/art-en-coloriages-xx";
  };

  const handleCover5Click = (e) => {
    window.location.href = "/projects/bethlehem";
  };

  const handleCover6Click = (e) => {
    window.location.href = "/projects/cerelia";
  };

  const handleCover7Click = (e) => {
    window.location.href = "/projects/cuisine-de-mon-père";
  };

  const handleCover8Click = (e) => {
    window.location.href = "/projects/cuisine-p'tites-poules";
  };

  const handleCover9Click = (e) => {
    window.location.href = "/projects/enTrain";
  };

  const handleCover10Click = (e) => {
    window.location.href = "/projects/femmes-débordées";
  };

  const handleCover11Click = (e) => {
    window.location.href = "/projects/intemporels";
  };

  const handleCover12Click = (e) => {
    window.location.href = "/projects/les-petites-routes";
  };

  const handleCover13Click = (e) => {
    window.location.href = "/projects/madMax";
  };

  const handleCover14Click = (e) => {
    window.location.href = "/projects/marmiton";
  };

  const handleCover15Click = (e) => {
    window.location.href = "/projects/le-guide-dea-prenoms";
  };

  const handleCover16Click = (e) => {
    window.location.href = "/projects/SG_Haussmann";
  };

  const handleCover17Click = (e) => {
    window.location.href = "/projects/thermomix-international";
  };

  const handleCover18Click = (e) => {
    window.location.href = "/projects/thermomix-poisson";
  };

  const handleCover19Click = (e) => {
    window.location.href = "/projects/thermomix-recettes-petits";
  };

  const handleCover20Click = (e) => {
    window.location.href = "/projects/trousselier";
  };

  const handleCover21Click = () => {
    // window.location.href = "/projects/mon-petit-monde"
    window.location.href = "/projects/miko";
  }

  const handleCover22Click = () => {
    window.location.href = "/projects/Enquête-sur-dieu"
  }

  const handleCover23Click = () => {
    window.location.href = "/projects/mon-petit-monde"
  }

  const handleCover24Click = () => {
    window.location.href = "/projects/petits-cahiers-dantan"
  }

  const handleCover25Click = () => {
    window.location.href = "/projects/50-refuges-de-montagne"
  }

  const handleCover26Click = () => {
    window.location.href = "/projects/cucina-povera-hachette-pratique"
  }

  const handleCover27Click = () => {
    window.location.href = "/projects/maison-déglon-coutellerie-déglon"
  }

  const handleCover28Click = () => {
    window.location.href = "/projects/magazine-théophile-hors-série"
  }

  return (
    <div className="slider-background">
      <div className="row">
        <div className="book1" onClick={handleCover1Click}>
          <img
            className="cover-img"
            alt="simplissime"
            src={SimplissimeCover}
          ></img>
          <div className="hover_div">Simplissime <br />{'\u2022'}&nbsp;Hachette Pratique</div>
        </div>
        <div className="book1 book2" onClick={handleCover2Click}>
          <img className="cover-img" alt="cover-2" src={PrehistoireCover}></img>
          <div className="hover_div">
            Reg'ART • L'art de la Préhistoire <br />{'\u2022'}&nbsp;Citadelles & Mazenod
          </div>
        </div>
        <div className="book1 book3" onClick={handleCover3Click}>
          <img
            className="cover-img"
            alt="cover-3"
            src={ArtColoriagesCover}
          ></img>
          <div className="hover_div">
          L'art en coloriage Chefs d'oeuvres <br />{'\u2022'}&nbsp;Citadelles & Mazenod
          </div>
        </div>
        <div className="book1 book4" onClick={handleCover4Click}>
          <img
            className="cover-img"
            alt="cover-4"
            src={ArtColoriagesXXCover}
          ></img>
          <div className="hover_div">
            L'Art en coloriages XXe siècle <br />{'\u2022'}&nbsp;Citadelles & Mazenod
          </div>
        </div>
      </div>
      <div className="row">
        <div className="book1 book21" onClick={handleCover25Click}>
          <img className="cover-img" alt="" src={RefugesCover}></img>
          <div className="hover_div">50 refuges de montagne{'\u2022'}&nbsp;Gallimard</div>
        </div>
        <div className="book1 book22" onClick={handleCover26Click}>
          <img className="cover-img" alt="" src={CucianCover}></img>
          <div className="hover_div">Cucina Povera{'\u2022'}&nbsp;Hachette Pratique</div>
        </div>
        <div className="book1 book23" onClick={handleCover27Click}>
          <img className="cover-img" alt="" src={MaisonCover}></img>
          <div className="hover_div">Maison Déglon{'\u2022'}&nbsp;Coutellerie Déglon</div>
        </div>
        <div className="book1 book23" onClick={handleCover28Click}>
          <img className="cover-img" alt="" src={TheophileCover}></img>
          <div className="hover_div">Magazine Théophile • Hors-Série</div>
        </div>
      </div>
      <div className="row">
      <div className="book1 book23" onClick={handleCover24Click}>
          <img className="cover-img" alt="" src={PetitCahierCover}></img>
          <div className="hover_div">Hors{'\u2022'}&nbsp;Collection</div>
        </div>
        <div className="book1 book5" onClick={handleCover5Click}>
          <img className="cover-img" alt="bethlehem" src={BethlehemCover}></img>
          <div className="hover_div">
            Livret Mobilités douces <br />{'\u2022'}&nbsp;Ville de Paris / coopération Bethléem
          </div>
        </div>
        <div className="book1 book6" onClick={handleCover6Click}>
          <img className="cover-img" alt="cover-6" src={CereliaCover}></img>
          <div className="hover_div">
            We are Cérélia <br />{'\u2022'}&nbsp;Livre d'entreprise CERELIA
          </div>
        </div>
        <div className="book1 book7" onClick={handleCover7Click}>
          <img
            className="cover-img"
            alt="cover-7"
            src={CuisineMonPereCover}
          ></img>
          <div className="hover_div">
            La cuisine de mon Père <br />{'\u2022'}&nbsp;Éditions Mango
          </div>
        </div>
      </div>
      {/* Remove the next row */}
      <div className="row">
      <div className="book1 book8" onClick={handleCover8Click}>
          <img
            className="cover-img"
            alt="cover-8"
            src={CuisineTitesPoulesCover}
          ></img>
          <div className="hover_div">
            La cuisine des P'tites Poules <br />{'\u2022'}&nbsp;Pocket Jeunesse
          </div>
        </div>
        <div className="book1 book9" onClick={handleCover9Click}>
          <img className="cover-img" alt="cover-9" src={EnTrainCover}></img>
          <div className="hover_div">En train • Éditions Gallimard</div>
        </div>
        <div className="book1 book10" onClick={handleCover10Click}>
          <img className="cover-img" alt="cover-10" src={FemmesCover}></img>
          <div className="hover_div">Femmes Débordées <br />{'\u2022'}&nbsp;Michel Lafon</div>
        </div>
        <div className="book1 book11" onClick={handleCover11Click}>
          <img
            className="cover-img"
            alt="cover-11"
            src={IntemporelsCover}
          ></img>
          <div className="hover_div">
            Collection Les Intemporels <br />{'\u2022'}&nbsp;Éditions Flammarion
          </div>
        </div>
        <br />
      </div>
      <div className="row">
        <div className="book1 cover13" onClick={handleCover13Click}>
          <img
            className="cover-img"
            alt="auf-einen-blick"
            src={MadMaxCover}
          ></img>
          <div className="hover_div">Mad Max<br />{'\u2022'}&nbsp;Sopra Steria </div>
        </div>
        <div className="book1 cover14" onClick={handleCover14Click}>
          <img className="cover-img" alt="marmiton" src={MarmitonCover}></img>
          <div className="hover_div">
            De l'astuce à l'assiette <br />{'\u2022'}&nbsp;Marmiton x Michel Lafon
          </div>
        </div>
        <div className="book1 book15" onClick={handleCover15Click}>
          <img className="cover-img" alt="" src={PrenomsCover}></img>
          <div className="hover_div">Guide des Prénoms <br />{'\u2022'}&nbsp;depuis 2016 <br />{'\u2022'}&nbsp;Éditions Solar</div>
        </div>
        <div className="book1 book12" onClick={handleCover12Click}>
          <img className="cover-img" alt="cover-12" src={LesPetitesCover}></img>
          <div className="hover_div">
            Les Petites Routes <br />{'\u2022'}&nbsp;Éditions Gallimard
          </div>
        </div>
      </div>
      <div className="row">
        <div className="book1 book17" onClick={handleCover17Click}>
          <img className="cover-img" alt="" src={ThermomixInternationalCover}></img>
          <div className="hover_div">Production livres <br />{'\u2022'}&nbsp;Vorwerk International</div>
        </div>
        <div className="book1 book18" onClick={handleCover18Click}>
          <img className="cover-img" alt="" src={ThermomixPoissonCover}></img>
          <div className="hover_div">
            Poissons et fruits de mer <br />{'\u2022'}&nbsp;Thermomix{'\u2022'}&nbsp;Vorwerk
          </div>
        </div>
        <div className="book1 book19" onClick={handleCover19Click}>
          <img className="cover-img" alt="" src={ThermomixRecettesCover}></img>
          <div className="hover_div">
            Recettes pour les Grands <br />&nbsp;et les Tout-Petits <br />{'\u2022'}&nbsp;Thermomix{'\u2022'}&nbsp;Vorwerk
          </div>
        </div>
        <div className="book1 book16" onClick={handleCover16Click}>
          <img className="cover-img" alt="" src={HaussmannCover}></img>
          <div className="hover_div">29 Haussman <br />{'\u2022'}&nbsp;Société Générale</div>
        </div>
      </div>
      <div className="row">
        <div className="book1 book20" onClick={handleCover20Click}>
          <img className="cover-img" alt="" src={TrousselierCover}></img>
          <div className="hover_div">
            L'album bébé Trousselier <br />{'\u2022'}&nbsp;Éditions Gründ
          </div>
        </div>
        <div className="book1 book21" onClick={handleCover21Click}>
          <img className="cover-img" alt="" src={MikoCover}></img>
          <div className="hover_div">Miko • Éditions Mame</div>
        </div>
        <div className="book1 book22" onClick={handleCover22Click}>
          <img className="cover-img" alt="" src={EnqueteDieuCover}></img>
          <div className="hover_div">Enquête sur Dieu <br />{'\u2022'}&nbsp;Éditions Mame</div>
        </div>
        <div className="book1 book23" onClick={handleCover23Click}>
          <img className="cover-img" alt="" src={MonPetitCover}></img>
               <div className="hover_div">Mon petite monde à décorer <br />{'\u2022'}&nbsp;Gallimard Jeunesse</div>
          {/* <div className="hover_div">Production livres <br />{'\u2022'}&nbsp;Vorwerk International</div> */}
        </div>
      </div>
      <Icon />
      {/* <HomeFooter /> */}
    </div>
  );
};

export default Home;
