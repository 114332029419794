import React from "react";
import PictureText from "../../../images/home/MadMax/MadMax-Project/Madmax_Text.jpg";
import Picture from "../../../images/home/MadMax/MadMax-Project/Madmax_Page.jpg";
import Picture1 from "../../../images/home/MadMax/MadMax-Project/Madmax_Page1.jpg";
import Picture2 from "../../../images/home/MadMax/MadMax-Project/Madmax_Page2.jpg";
import Picture3 from "../../../images/home/MadMax/MadMax-Project/Madmax_Page3.jpg";
import Picture4 from "../../../images/home/MadMax/MadMax-Project/Madmax_Page4.jpg";
import Picture5 from "../../../images/home/MadMax/MadMax-Project/Madmax_Page5.jpg";

const Cover2page = () => {
  return (
    <div className="cover-container">
      <div className={`productViewFirst`} style={{ "--bg-color": "rgb(179,0,29)",  "--text-color": "rgb(255,255,255)" }} >
        <h1 className="productViewFirst_h1">Mad Max • Sopra Steria </h1>
        <h2 className="productViewFirst_h2">
          Conception • Création graphique et éditoriale • Réalisation • Impression
        </h2>
        <h2 className={`productViewFirst_p`} style={{ "--text-font": "1.7vw"}} >
        Sopra Steria, leader européen de la transformation numérique, 
        souhaitait relater à travers les témoignages des collaborateurs, 
        l'expérience et les bénéfices du projet MadMax, une réorganisation de projet en mode X-Shore, 
        mené conjointement par les équipes de Lyon et de Madrid. Une conception graphique dynamique, 
        des infographies ont permis de donner vie à ce projet. 
        </h2>
      </div>
       {/* <div className="inside-page">
        <img className="coverimg" src={PictureText} alt="PageText" />
      </div> */}
      <div className="inside-page">
        <img className="coverimg" src={Picture} alt="Page" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture4} alt="Page 4" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture5} alt="Page 5" />
      </div>
    </div>
  );
};

export default Cover2page;
