import React from "react";
import Picture1 from "../../../images/home/Theophile/01.jpg";
import Picture2 from "../../../images/home/Theophile/02.jpg";
import Picture3 from "../../../images/home/Theophile/03.jpg";
import Picture4 from "../../../images/home/Theophile/04.jpg";
import Picture5 from "../../../images/home/Theophile/05.jpg";

const TheophilePage = () => {
  return (
    <div className="cover-container">
     <div className={`productViewFirst`} style={{ "--bg-color": "#6FBBB7", "--text-color": "black" }}>
        <h1 className="productViewFirst_h1">Magazine Théophile • Hors-Série </h1>
        <h1 className="productViewFirst_h1">Rédacteur en chef • Gestion éditoriale et graphique </h1>
        <h2 className={`productViewFirst_p`} style={{ "--text-font": "1.7vw"}} >
        Théophile est une nouvelle revue pour les enfants de 7-12 ans qui aborde une thématique sous toutes ses coutures pour découvrir les beautés du monde qui les entoure et mettre des étoiles dans leurs yeux !

Dans ce hors-série d'été de Théophile :

<ul>
  <li>Un dossier scientifique passionnant sur le soleil et la lune.</li>
  <li>Une interview de Sophie Adenot, astronaute</li>
  <li>Le top 10 de la conquête spatiale.</li>
  <li>Un reportage : éclipses, cache-cache dans l'espace.</li>
  <li>Un voyage aux pays des légendes du soleil, le soleil et la lune dans l'art</li>
  <li>Des BD, une histoire à lire, et même une pièce de théâtre à monter !</li>
  <li>Une fusée qui décolle à fabriquer, une carte du ciel à monter soi-même, et de nombreux jeux. </li>

</ul>
        </h2>
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture4} alt="Page 4" />
      </div> 
       <div className="inside-page">
        <img className="coverimg" src={Picture5} alt="Page 4" />
      </div>
    </div>
  );
};

export default TheophilePage;
