import React from "react";
// import Picture from "../../../images/home/PetitCahier/PetitCahier-Project/Miko_Text.jpg";
import Picture1 from "../../../images/home/Refuges/Refuges_Page1.jpg";
import Picture2 from "../../../images/home/Refuges/Refuges_Page2.jpg";
import Picture3 from "../../../images/home/Refuges/Refuges_Page3.jpg";
import Picture4 from "../../../images/home/Refuges/Refuges_Page4.jpg";
import Picture5 from "../../../images/home/Refuges/Refuges_Page5.jpg";
import Picture6 from "../../../images/home/Refuges/Refuges_Page6.jpg";
import Picture7 from "../../../images/home/Refuges/Refuges_Page7.jpg";
// import Picture4 from "../../../images/home/PetitCahier/PetitCahier-Project/PetitCahier_Page4.jpg";

const RefugesPage = () => {
  return (
    <div className="cover-container">
     <div className={`productViewFirst`} style={{ "--bg-color": "#d4e8f1", "--text-color": "black" }}>
        <h1 className="productViewFirst_h1">50 refuges de montagne - Gallimard</h1>
        <h1 className="productViewFirst_h1">Fédération française des clubs alpins et de montagne</h1>
        <h2 className={`productViewFirst_p`} style={{ "--text-font": "1.7vw"}} >
          <h2 className="productViewFirst_h2">
            Conception • Création graphique et éditoriale • Réalisation
          </h2> 
        Randonnées, alpinisme, nature sauvage… des expériences inoubliables !

        Des refuges accessibles facilement pour les familles, certains réservés aux alpinistes chevronnés, d’autres encore pour les amateurs fascinés... Des pages thématiques pour tout savoir sur les gardiens, les nuits en refuge, la faune et la flore, l’observation du ciel ou les bons petits plats cuisinés par les gardiens... sans oublier les problématiques du climat. En plus, toutes les informations pratiques pour partir à l’assaut de la montagne, de l’équipement au comportement en refuge ! 
        </h2>
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture4} alt="Page 4" />
      </div> 
       <div className="inside-page">
        <img className="coverimg" src={Picture5} alt="Page 4" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture6} alt="Page 5" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture7} alt="Page 6" />
      </div>
    </div>
  );
};

export default RefugesPage;
