import React from "react";
import HomeFooter from "../../footer/HomeFooter";
import Picture from "../../../images/home/Trousselier/Trousselier-Project/TROUSSELIER_Text.jpg";
import Picture1 from "../../../images/home/Trousselier/Trousselier-Project/TROUSSELIER_Page1.jpg";
import Picture2 from "../../../images/home/Trousselier/Trousselier-Project/TROUSSELIER_Page2.jpg";
import Picture3 from "../../../images/home/Trousselier/Trousselier-Project/TROUSSELIER_Page3.jpg";
// import Picture4 from "../../../images/home/Trousselier/Trousselier-Project/TROUSSELIER_Page4.jpg";

const TrousselierPage = () => {
  return (
    <div className="cover-container">
    <div className={`productViewFirst`} style={{ "--bg-color": "rgb(172,214,236)",  "--text-color": "black" }} >
        <h1 className="productViewFirst_h1">L'album bébé Trousselier • Éditions Gründ</h1>
        <h2 className="productViewFirst_h2">
        Conception • Création graphique et éditoriale • Réalisation
        </h2>
        <h2 className={`productViewFirst_p`} style={{ "--text-font": "1.7vw"}} >
        Une belle collaboration avec la marque Trousselier pour cet album de naissance illustré du héros de la marque l'Ange Lapin, 
        qui permet de conserver les souvenirs de la grossesse et des premières année de son bébé.  
        </h2>
      </div>
      {/* <div className="inside-page">
        <img className="coverimg" src={Picture} alt="Page" />
      </div> */}
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div>
      {/* <div className="inside-page">
        <img className="coverimg" src={Picture4} alt="Page 4" />
      </div> */}
      {/* <div className="inside-page">
        <img className="cover14img4" src={Picture4} alt="Page 4" />
      </div>
      <div className="inside-page">
        <img className="cover14img5" src={Picture5} alt="Page 5" />
      </div>
      <div className="inside-page">
        <img className="cover14img6" src={Picture5} alt="Page 6" />
      </div>
      <div className="inside-page">
        <img className="cover14img7" src={Picture5} alt="Page 7" />
      </div> */}
    </div>
  );
};

export default TrousselierPage;
