import React from "react";
import Picture from "../../../images/home/Miko/Miko-Project/Miko_Text.jpg";
import Picture1 from "../../../images/home/Miko/Miko-Project/Miko_Page1.jpg";
import Picture2 from "../../../images/home/Miko/Miko-Project/Miko_Page2.jpg";
import Picture3 from "../../../images/home/Miko/Miko-Project/Miko_Page3.jpg";
import Picture4 from "../../../images/home/Miko/Miko-Project/Miko_Page4.jpg";

const MikoPage = () => {
  return (
    <div className="cover-container">
      <div className={`productViewFirst`} style={{ "--bg-color": "rgb(202,207,210)",  "--text-color": "black" }} >
        <h1 className="productViewFirst_h1">Miko • Éditions Mame</h1>
        <h2 className="productViewFirst_h2">
        Conception • Création graphique et éditoriale <br />• Réalisation
        </h2>
        <h2 className={`productViewFirst_p`} style={{ "--text-font": "1.4vw"}} >
        Un documentaire d'un genre tout à fait inédit où se mêlent récit, 
        planches de manga et pages documentaires pour découvrir la Bible et la foi chrétienne. 
        Une quête à travers la Bible raconte l'histoire de Miko, un jeune garçon qui vient de perdre son grand-père bien-aimé. 
        En découvrant la Bible qu'il lui a laissée, accompagnée d'une lettre mystérieuse qui l'invite à partir à la recherche de 7 secrets, 
        Miko plonge dans un univers inconnu : les 7 passages clefs préparés par le grand-père qui lui permettront de découvrir ce à quoi il croyait. 
        Aidé dans sa quête par un vieux moine ami du grand-père, et par ses fidèles amis Gaby et Rafy, Miko vit une véritable aventure spirituelle, 
        entre passages de la Bible qui prennent vie devant ses yeux ébahis, échanges avec le vieux moine Honorius qui le guide dans cet univers inconnu, 
        et l'amitié de ses deux copains qui le soutiennent et l'encouragent à percer les 7 secrets du mystère qu'est le sens de la vie humaine. 
        Un livre à offrir à tous les enfants qui se posent des questions sur le sens de la vie et de la foi chrétienne, 
        conçu par des spécialistes de l'univers manga dont les enfants raffolent. 
        </h2>
      </div>
     {/* <div className="inside-page">
        <img className="coverimg" src={Picture} alt="Page" />
      </div> */}
      <div className="inside-page">
        <img className="coverimg" src={Picture1} alt="Page 1" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture2} alt="Page 2" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture3} alt="Page 3" />
      </div>
      <div className="inside-page">
        <img className="coverimg" src={Picture4} alt="Page 4" />
      </div>
      {/* <div className="inside-page">
        <img className="cover8img4" src={Picture4} alt="Page 4" />
      </div>
      <div className="inside-page">
        <img className="cover8img5" src={Picture5} alt="Page 5" />
      </div> */}
    </div>
  );
};

export default MikoPage;
